<template>
  <div id="app">

    <div v-if="!isIE" class="lv-web-site-header">
      <div class="lv-web-site-header-container">
        <img class="lv-web-site-header-log" src="@/assets/images/public/logo@2x.png">
        <div class="lv-web-site-header-navigator">
          <router-link to="/">Home</router-link>
          <router-link to="/coreService">Core Services</router-link>
          <router-link to="/events">Events</router-link>
          <router-link to="/partner">Our Partners</router-link>
          <router-link to="/aboutUs">About Us</router-link>
        </div>
        <el-dropdown>
          <span class="el-dropdown-link">
            EN<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <a style="text-decoration: none;color: inherit" href="https://www.lawback.com/">CN</a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div v-else class="window-alert-danger">
      You are using the <strong> outdated </strong> browser. It's time to
      <a href="https://chrome.en.softonic.com/"> change a better browser </a>
      to improve the user experience</div>
    <router-view/>
    <div class="lv-web-site-footer">
      All rights reserved LawBack Network Technology Co.Ltd. 备案号：苏ICP备18063438号苏公网安备 32011402010384号

    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  computed:{
    isIE(){
      if(navigator.appName == "Microsoft Internet Explorer"&&parseInt(navigator.appVersion.split(";")[1].replace(/[ ]/g, "").replace("MSIE",""))<=9){
       return true
      }
      return false
    }
  },
}
</script>

<style lang="scss">

  body{
    margin: 0;
    padding: 0;
    #app{
      font-size: 12px;
      font-family: Arial;
      padding-top: 80px;
    }
  }
  .lv-web-site-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 0px 9px 0px rgba(51, 51, 51, 0.17);
    z-index: 100;
    .lv-web-site-header-container{
      width: 1200px;
      height: 80px;
      margin: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .el-dropdown-link{
        color: $content;
        cursor: pointer;
      }
      .lv-web-site-header-log{
        width: 270px;
        height: 30px;
      }
      .lv-web-site-header-navigator{
        width: 700px;
        display: flex;
        position: relative;
        &:after{
          content: '';
          position: absolute;
          transform: translateY(-50%);
          top:50% ;
          right: 0;
          width: 1px;
          height: 26px;
          background-color: #DADADA;
        }
        >a{
          width: 150px;
          text-align: center;
          font-size: 18px;
          color:$title;
          line-height: 80px;
          text-decoration: none;
          font-weight: 400;
          position: relative;
          display: inline-block;
          &:hover,&.router-link-exact-active{
            color: $primary;
            font-weight: 600;
          }
          &.router-link-exact-active:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 120px;
            height: 4px;
            background: #326069;
          }
        }

      }
    }
  }
  .lv-web-site-footer{
    margin-top: 60px;
    height: 80px;
    background: #050809;
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 80px;
    text-align: center;
  }

</style>
