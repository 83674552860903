<template>
    <div class="why-lawback" >

        <div class="lv-basic-card lv-mb-72" >
            <easy-news-item>
                <template v-slot:image>
                    <img class="home-new-image-item" src="@/assets/images/home/image_what.png" alt="">
                </template>
                <template v-slot:content>
                    <easy-title class="lv-mb-24">
                        What is Lawback ?
                    </easy-title>
                    <div class="home-new-context">
                        <div class="lv-mb-40">
                            Lawback is leading international legal service platform in China, providing convenient, efficient and
                            affordable legal service for Chinese outbound enterprises with the co-work with internal and external lawyers
                            in the practice area of internal and external lawyers in the practice area of international investment,
                            international trade, litigation, arbitration, intellectual property and coorporate. etc. Lawback also provides
                            legal services for foreign enterprises or individuals with Chinese lawyers’ cooperation.
                        </div>
                        <div>
                            <strong>
                                It enjoys clients of over 216,000 member enterprises and a lot of consultancies and<br>
                                commission every month.
                            </strong><br>
                            <strong>
                                Lawback now has collaborated with law firms or legal and financial agencies in over 90<br> countries andregions.
                            </strong>
                        </div>
                    </div>
                </template>
            </easy-news-item>
        </div>
        <div class="home-page-why-lawback">
            <easy-title class="lv-mb-24">
                Why Lawback ?
            </easy-title>
            <div class="lv-mini-title lv-mb-24">
                CCPIT's Support
            </div>
            <div class="lv-article-text lv-mb-24">
                Lawback is strongliy supported by Commercial & Legal Service Center of China Council for the Promotion of International Trade, which is a nationalforeign trade and
                investment Trade, which is a national foreign trade and inverstment promotion agency. ICC China is affialiated to CCPIT.
            </div>
            <i class="quote lv-mb-16 block">
                With the  support of CCPIT, Lawback shares CCPIT's  domestic enterprises resources and global commerce resources.
            </i>
            <div class="lv-images-wall-wrapper lv-mb-52">
                <img class="lv-images-wall-img" src="@/assets/images/home/image_why01.png">
                <img class="lv-images-wall-img" src="@/assets/images/home/image_why02.png">
                <img class="lv-images-wall-img" src="@/assets/images/home/image_why03.png">
                <img class="lv-images-wall-img" src="@/assets/images/home/image_why04.png">
            </div>
            <div class="lv-mini-title lv-mb-24">Abundant Resources</div>
            <div class="lv-mini-subTitle lv-mb-24">Global  Attorneys Network</div>
            <div class="lv-article-text lv-mb-24">
                Lawback is building a global attorneys network, which makes the transnational legal cooperation easier and more efficient. It’s also a legal resource tank, gathering
                external attorneys, Chinese attorneys, enterprises, individuals, finance agencies, bar association and commerce assocation for efficient connection and business
                contacts through Lawback platform without time and zone limits, language limit and resources limits. With the network and Lawback platform, innumberable possibilities
                could be created.
            </div>
            <img class="website-ad-banner lv-mb-28" src="@/assets/images/home/image_why05.png" />
            <div class="lv-mini-subTitle lv-mb-24">
                Enterprises and Commerce association
            </div>
            <div class="lv-article-text lv-mb-52">
                216,000 member enterprises (including CCPIT's) and 30 + commerce associations
            </div>
            <div class="lv-mini-title lv-mb-24">Online and Offline Multi-channels Promotion</div>
            <multi-channels></multi-channels>
            <WhatCanYouDo></WhatCanYouDo>
            <WhatServicesCanYouEnjoy ></WhatServicesCanYouEnjoy>
        </div>
    </div>
</template>

<script>
    import MultiChannels from "../MultiChannels/MultiChannels";
    import WhatCanYouDo from "../WhatCanYouDo/WhatCanYouDo";
    import WhatServicesCanYouEnjoy from "../WhatServicesCanYouEnjoy/WhatServicesCanYouEnjoy";
    import EasyNewsItem from "@/components/EasyNewsItem/EasyNewsItem";
    import EasyTitle from "@/components/EasyTitle/EasyTitle";
    export default {
        name: "WhyLawback",
        components:{
            MultiChannels,
            WhatCanYouDo,
            WhatServicesCanYouEnjoy,
            EasyNewsItem,
            EasyTitle
        }
    }
</script>

<style lang="scss" scoped>
    .why-lawback{


    .home-new-image-item{
        height: 332px;
    }
    .home-new-context{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        word-spacing: -2px;
    }
    .home-page-why-lawback{
        width: 1200px;
        margin: 0 auto;
    }
    }
</style>