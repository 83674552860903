<template>
    <div class="partner-list-item"  >
        <div class="partner-log" :style="{'background-image':'url('+org.coverUrl+')'}"></div>
        <div ref="mask" :class="`partner-info-mask ${animateClass}`">
            <div class="partner-name">
                {{ org.nameEn?org.nameEn:' ' }}
            </div>
            <div class="partner-remark">
                {{ org.remarkEn?org.remarkEn:' ' }}
            </div>
        </div>
    </div>
</template>

<script>
    import "animate.css";
    export default {
        name: "partnerItem",
        data(){
          return {
              animateClass:""
          }
        },
        props:{
          org:{
              type:Object,
              default(){
                  return {}
              }
          }
        },
        methods:{
        }
    }
</script>

<style lang="scss" scoped>
    .partner-list-item{
        width: calc(20% - 13px);
        height: 226px;
        border: 1px solid #E4E4E4;
        margin-right: 16px;
        margin-bottom: 16px;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
    >div.partner-log{
        position: relative;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size:contain;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
    }
    &:hover .partner-info-mask{
        top: 0%;
    }
    >.partner-info-mask{
        position: absolute;
        left: 0%;
        top: 100%;
        width: 100%;
        height: 100%;
        transition: .3s ease-in;
        background-color: rgba(0,0,0,0.4);
        color: white;
        padding: 47px 17px 0;
        box-sizing: border-box;
        cursor: pointer;
        z-index: 2;
    .partner-name{
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height:20px;
    }
    .partner-remark{
        margin-top: 6px;
        font-size: 16px;
        line-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
    }
    }
    }
</style>