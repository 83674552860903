import Vue from "vue";
import axios from "./axios";
import http from "./http";
import drag from "./el-dialog-drag";
import {Message} from "element-ui";
import moment from "moment";
import _ from "lodash";

moment.locale("zh-cn");

let Plugin = {}

Plugin.install = function(Vue) {
    Vue.directive("el-drag-dialog", drag);
    //时间过滤器
    Vue.filter("formatDate", function(value, format) {
        if (!value) return "";
        if (value < 1500000000000) {
            value = value * 1000;
        }
        return moment(value).format(format ? format : "YYYY-MM-DD HH:mm:ss");
    });
    //时间过滤器
    Vue.filter("formatYear", function(value) {
        if (!value) return "";
        return moment(value, "X").format("YYYY");
    });
    //时间过滤器
    Vue.filter("formatDay", function(value) {
        if (!value) return "";
        if (value < 1500000000000) {
            value = value * 1000;
        }
        return moment(value).format("YYYY-MM-DD");
    });

    //千分符过滤器
    Vue.filter("formatNum", function(value) {
        value += "";
        const valueArr = value.split(".");
        valueArr[0] = valueArr[0].replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        return valueArr.join(".");
    });
    //event bus
    Vue.prototype._ = _;
    window.moment = Vue.prototype.moment = moment;
    // 网络请求
    Vue.prototype.$http = http;
    Vue.prototype.$axios = axios;
    //模拟Optional Chaining
    Vue.prototype.$oc = function(obj, ...path) {
        let tmp = obj;
        for (let key of path) {
            if (tmp) {
                tmp = tmp[key];
            } else {
                break;
            }
        }
        return tmp || "";
    };
    //全局异常处理
   /* Vue.config.errorHandler = function(err, vm, info) {
        console.error(err, vm, info);
        Message.error(err);
    };*/
};
Vue.use(Plugin);

export default Plugin;
