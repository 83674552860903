<template>
   <div class="lawyer-slide-wrapper lv-basic-card lv-mt-80 lv-mb-72">
       <easy-title class="lv-mb-24">Lawyers</easy-title>
       <div class="lawyer-slide-buttons lv-mb-24">
           <el-button :class="{'lv-primary-round-button':continentCode==''}" @click="getLawyers('')"  >All</el-button>
           <el-button :class="{'lv-primary-round-button':continentCode==item.code}"
                      v-for="(item,index) in continents" @click="getLawyers(item.code)"
                      :key="index" >
               {{item.nameEn}}
           </el-button>
       </div>
       <swiper  ref="mySwiper"  :options="swiperOptions">
           <swiper-slide v-for="(item,index) in lawyers" :key="index"   >
                   <div class="lawer-slider-list">
                       <LawyerCard v-for="(lawyer) in item" :key="lawyer.uid" :lawyerData="lawyer" :domainList ="domainList"  class="lawer-slider-item"></LawyerCard>
                   </div>
           </swiper-slide>
           <div class="lawyer-pagination" slot="pagination"></div>
       </swiper>

   </div>
</template>

<script>
    import { swiper, swiperSlide} from 'vue-awesome-swiper';
    import 'swiper/swiper.scss';
    import LawyerCard from "@/components/LawyerCard/LawyerCard";
    import EasyTitle from "../EasyTitle/EasyTitle";
    export default {
        name: "lawyerList",
        data(){
            return {
                continents:[],
                lawyers:[],
                domainList:[],
                swiperOptions: {
                  autoplay: 5000,
                  autoplayDisableOnInteraction:false,
                  setWrapperSize :true,
                  pagination : '.lawyer-pagination',
                  loop:true,
                  paginationClickable :true,
                  mousewheelControl : true,

                    // Some Swiper option/callback...
                },
                continentCode:'',
            }
        },
        components:{
            EasyTitle,
            swiper, swiperSlide, LawyerCard
        },
        watch:{
          lawyers:{
              handler(){
                let _this = this;
                 this.$nextTick(function () {
                    setTimeout(()=>{
                      if(!_this.$refs.mySwiper)return;
                        let bullets = _this.$refs.mySwiper.$el.querySelectorAll(".swiper-pagination-bullet");
                        bullets.forEach(function (item,i) {
                            item.onmouseenter=function () {
                                item.click();
                            }
                        });
                    },50)
                  })
                  },
              immediate:true

          }
        },
        created() {
            this.getContinents();
            this.getLawyers();
            this.getDomains();
        },
        mounted() {
            console.log(this.$refs.mySwiper)
        },
        methods:{
            async getContinents(){
                let data = await this.$http.get("/cms/common/CONTINENT_TYPE");
                this.continents = data.filter(item=>item.code!="AN");
            },
            async getDomains(){
                let data = await this.$http.get("/cms/domain/list");
                this.domainList= data;
            },
            async getLawyers(continentCode=''){
                this.continentCode = continentCode;
                let _this = this;
                let data = await this.$http.get("/user/lawyer/list/page",{size:9999,page:1,continentCode:continentCode});
                let list = data.list.slice(0,20).map(item=>{
                    return {
                        ...item.lawyer,
                        ...item.user
                    };
                });
                this.lawyers = [];
                for(let i=0;i<Math.ceil(list.length/4);i++){
                    let arr = [];
                    for(let j=0;j<4;j++){
                        let k = i*4+j;
                        if(list[k]){
                            arr.push(list[k])
                        }
                    }
                    _this.lawyers.push(arr);
                }
            }

        }
    }
</script>

<style lang="scss" >
.lawyer-slide-wrapper{
    .lawyer-slide-buttons{
        display: flex;
        justify-content: space-between;
        .el-button{
            width: 150px;
            box-sizing: border-box;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .lawer-slider-list{
        display: flex;
        min-height: 420px;
        >div.lawer-slider-item{
            width: 283px;
            height: 421px;
            background-color: silver;
            border-radius: 10px;
            margin-right: 23px;
        }
        >div.lawer-slider-item:last-child{
            margin-right: 0;
        }
    }
    .lawyer-pagination{
        margin: 12px auto 30px;
        text-align: center;
    }
}
</style>