<template>
    <div class="lawyer-item-card">
        <img class="lawyer-item-bg" :src="lawyerData.idPhoto"></img>
        <div v-if="false"  class="lawyer-item-country">{{lawyerData.countryName}}</div>
        <div class="lawyer-item-info">
            <div class="lawyer-item-name">{{lawyerData.actualName}}</div>
            <div class="lawyer-item-tags">
                <div :title="name" v-for="(name,index) in mainlyFieldNames.slice(0,3)" :key="index" class="lawyer-item-tag">
                    {{name.length>20?name.slice(0,20)+'...':name}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"LawyerCard",
        props:{
            lawyerData:{
                type:Object,
                default(){
                    return {};
                }
            },
            domainList:{
                type:Array,
                default(){
                    return [];
                }
            }
        },
        computed:{
            mainlyFieldNames(){
                if(this.lawyerData && !this.lawyerData.mainlyField)return [];
                let list = [],trans = [];
                list = this.lawyerData.mainlyField.split(",");
                list.forEach(item=>{
                   let domain = this.domainList.find(domain=>domain.id == item);
                   domain?trans.push(domain.nameEn || domain.name):trans.push(item)
                })
                return trans;
            }
        }

    }
</script>

<style lang="scss" scoped>
.lawyer-item-card {
    position: relative;
    width: 320px;
    height: 474px;
    border-radius: 10px;
    overflow: hidden;
    .lawyer-item-bg {
        display: block;
        width: 100%;
        height: 100%;
    }
    .lawyer-item-country {
        position: absolute;
        top: 12px;
        left: 9px;
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
        color: #fff;
        font-size: 15px;
        border-radius: 20px;
        background-color: rgba(0,0,0,0.5);
    }
    .lawyer-item-info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 17px 0 0 9px;
        color: #fff;
        background-color: rgba(0,0,0,0.5);
        @media screen and (max-width: 1440px){
            height: 160px;
        }
        @media screen and (min-width: 1440px){
            height: 100px;
        }
        .lawyer-item-name {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .lawyer-item-tags {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .lawyer-item-tag{
                padding: 4px 8px;
                font-size: 12px;
                margin-right: 6px;
                border: 1px solid #ffffff;
                width: 86px;
                box-sizing: border-box;
                text-align: center;
                word-break: normal;

            }
        }
    }
}
</style>
