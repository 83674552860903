import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/events/events.vue')
  },
  {
    path: '/coreService',
    name: 'coreService',
    component: () => import('../views/coreService/coreService.vue')
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import('../views/partner/partner.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs/aboutUs.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 500)
      })
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
