const api = Object.create(null)

const components=require.context('./', true, /Easy\w+\.vue$/);

function getFileName(str) {
    var pos=str.lastIndexOf("\/");
    var pos2=str.lastIndexOf(".");
//strFileName=str.substring(pos+1);//文件名带后缀
    return str.substring(pos+1,pos2);//文件名不带后缀;
}

const install = (Vue) => {
  try{
      components.keys().forEach(fileName => {
          console.log(fileName)
          // 获取组件配置
          const componentModule = components(fileName);
          const Config = componentModule.default || componentModule;
          Vue.component(getFileName(fileName), Config);
      });
  }catch (e) {
      //throw e;
  }
};

export default {
    install,
}
