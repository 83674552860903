<template>
    <div class="what-services-can-you-enjoy-wrapper">
        <easy-title id="whatServicesCanYouEnjoy"  class="lv-mt-80 lv-mb-52">
            What services can you enjoy ?
        </easy-title>
        <div class="what-services-can-you-enjoy-container">
            <div class="what-services-can-you-enjoy-item mb-22">
                <p>Barrier-free Access to enterprises<br>
                    and commerce associations in<br>
                    China</p>
                <img class="what-services-can-you-enjoy-item-icon" src="@/assets/images/home/icon_enjoy01@2x.png">
                <h4>01</h4>
            </div>
            <div class="what-services-can-you-enjoy-item mb-22">
                <p>Join in the Lawback Global<br> Attorneys Network</p>
                <img class="what-services-can-you-enjoy-item-icon" src="@/assets/images/home/icon_enjoy02@2x.png">
                <h4>02</h4>
            </div>
            <div class="what-services-can-you-enjoy-item mb-22">
                <p>
                    Particiate in Lawback<br>
                    Webinars
                </p>
                <img class="what-services-can-you-enjoy-item-icon" src="@/assets/images/home/icon_enjoy03@2x.png">
                <h4>03</h4>
            </div>
            <div class="what-services-can-you-enjoy-item">
                <p>Online and Offline  promotion</p>
                <img class="what-services-can-you-enjoy-item-icon" src="@/assets/images/home/icon_enjoy04@2x.png">
                <h4>04</h4>
            </div>
            <div class="what-services-can-you-enjoy-item">
                <img class="what-services-can-you-enjoy-item-icon" src="@/assets/images/home/icon_enjoy05@2x.png">
                <p>Privilege at the ICLCF</p>
                <h4>05</h4>

            </div>
            <div class="what-services-can-you-enjoy-item">
                <p>Online News and Publications</p>
                <img class="what-services-can-you-enjoy-item-icon" src="@/assets/images/home/icon_enjoy06@2x.png">
                <h4>06</h4>
            </div>
        </div>
    </div>
</template>

<script>
    import EasyTitle from "../EasyTitle/EasyTitle";
    export default {
        name: "WhatServicesCanYouEnjoy",
        components: {EasyTitle}
    }
</script>

<style lang="scss" scoped>
    .what-services-can-you-enjoy-container{
        display: flex;
        flex-wrap: wrap;
        >div .what-services-can-you-enjoy-item-icon{
            content: "";
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            display: block;
        }
        >div:nth-child(1) .what-services-can-you-enjoy-item-icon{
            width: 109px;
            height: 130px;
        }
        >div:nth-child(2) .what-services-can-you-enjoy-item-icon{
            width: 140px;
            height: 120px;
        }
        >div:nth-child(3) .what-services-can-you-enjoy-item-icon{
            width: 118px;
            height: 113px;
        }
        >div:nth-child(4) .what-services-can-you-enjoy-item-icon{
            width: 118px;
            height: 119px;
        }
        >div:nth-child(5) .what-services-can-you-enjoy-item-icon{
            width: 123px;
            height: 107px;
        }
        >div:nth-child(6) .what-services-can-you-enjoy-item-icon{
            width: 102px;
            height: 122px;
        }
        div.what-services-can-you-enjoy-item{
            width: 385px;
            height: 195px;
            padding: 34px 44px;
            background: #FFFFFF;
            border: 1px solid #E4E4E4;
            box-sizing: border-box;
            border-radius: 10px;
            position: relative;
            z-index: 2;
            margin-right: 22px;
            p{
                font-size: 20px;
                font-family: Arial;
                font-weight: 400;
                color: $content;
                line-height: 30px;
                letter-spacing: -1px;
                margin: 0;
            }
            h4{
                font-size: 36px;
                font-weight: 900;
                color: #FFA525;
                line-height: 36px;
                position: absolute;
                left: 16px;
                bottom: 32px;
                margin: 0;
            }
            &:hover{
                box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.21);
            }
        }
        >div.what-services-can-you-enjoy-item:nth-child(3n){
            margin-right: 0;
        }
        .mb-22{
            margin-bottom: 22px;
        }
    }

</style>