import 'babel-polyfill';
import promise from 'es6-promise';
promise.polyfill()
import "@/plugins/array/index"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/plugins/index"
import ElementUI from 'element-ui';
//import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/scss/element-variables.scss';
import "@/utils/publicMethods"
//图片懒加载
import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'dist/error.png',
  attempt: 1
})
Vue.use(ElementUI,{
  size:'medium'
});
import EasyUI from "@/components/index";

Vue.config.productionTip = false;
//全剧组件注册
Vue.use(EasyUI);
//rem重置尺寸
function buildFontSizeBydesign(baseWidth = 750) {
  const dpr = window.devicePixelRatio;
  var currentWidth = document.documentElement.clientWidth;
  currentWidth = currentWidth > 1200?currentWidth:1200;
  let remSize = 0;
  let scale = 0;
  scale = currentWidth / baseWidth;
  remSize = baseWidth / 10;
  remSize = remSize * scale;
  document.documentElement.style.fontSize = remSize + 'px';
  document.documentElement.setAttribute('data-dpr', `${dpr}`);
}
buildFontSizeBydesign(1920);
window.addEventListener('resize',function () {
  buildFontSizeBydesign(1920);
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
