<template>
    <div class="what-can-you-do-wrapper">
        <easy-title id="whatCanYouDo" class="lv-mt-80 lv-mb-52">
            What can you do on Lawback ?
        </easy-title>
        <div class="what-can-you-do-container">
            <div class="what-can-you-do-item">
                <img src="@/assets/images/home/pic_do01@2x.png">
                <div class="what-can-you-do-item-footer">
                    Get access to more cases
                </div>
            </div>
            <div class="what-can-you-do-item">
                <img src="@/assets/images/home/pic_do02@2x.png">
                <div class="what-can-you-do-item-footer">
                    Promote you brand and services
                </div>
            </div>
            <div class="what-can-you-do-item">
                <img src="@/assets/images/home/pic_do03@2x.png">
                <div class="what-can-you-do-item-footer">
                    Devlelop business in Chinese market
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EasyTitle from "../EasyTitle/EasyTitle";
    export default {
        name: "WhatCanYouDo",
        components: {EasyTitle}
    }
</script>

<style lang="scss" scoped>
.what-can-you-do-container{
    display: flex;
    justify-content: space-between;
    div.what-can-you-do-item{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.21);
        img{
            width: 385px;
            height: 200px;
            background: #FFFFFF;
        }
        >div.what-can-you-do-item-footer{
            width: 385px;
            height: 90px;
            background: #FFFFFF;
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            color: #666666;
            line-height: 90px;
        }
    }
}
</style>