<template>
    <div class="easy-news">
        <div v-if="isReverse" class="easy-news-content">
            <slot name="content"></slot>
        </div>
        <div class="easy-news-image">
            <slot name="image"></slot>
        </div>
        <div v-if="!isReverse" class="easy-news-content">
          <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EasyNewsItem",
        props:{
            isReverse:{
                type:Boolean,
                default:false
            }
        }
    }
</script>

<style lang="scss">
    .easy-news{
        width: inherit;
        display: flex;
        justify-content: space-between;
    .easy-news-image{
        width: 420px;;
    img{
        width: 100%;
        }
    }
    .easy-news-content{
        width:700px;
    .easy-news-title{
        font-size: 36px;
        font-weight: bold;
        color: $title;
        line-height:36px;
      }
     }
    }
</style>