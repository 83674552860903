<template>
  <div class="partner-list-wrapper">
      <swiper v-if="orgList.length>0" ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(item,index) in orgList" :key="index" >
              <div class="partner-list">
                  <partner-item v-for="(org) in item.slice(0,5)" :key="org.id" :org="org" ></partner-item>
              </div>
              <div class="partner-list">
                  <partner-item v-for="(org) in item.slice(5,10)" :key="org.id" :org="org" ></partner-item>
              </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <!-- <template v-slot:pagination>
               <div class="swiper-pagination" >

               </div>
           </template>-->
      </swiper>
  </div>
</template>

<script>
    import { swiper, swiperSlide,directive} from 'vue-awesome-swiper';
    import partnerItem from "./partnerItem";
    import 'swiper/swiper.scss';

    export default {
        name: "partnerList",
        components:{
            swiper, swiperSlide,partnerItem
        },
        directives: {
            swiper: directive
        },
        data(){
            return {
                swiperOptions: {
                  autoplay: 5000,
                  autoplayDisableOnInteraction:false,
                  setWrapperSize :true,
                  pagination : '.swiper-pagination',
                  loop:true,
                  paginationClickable :true,
                  mousewheelControl : true,
                },
                orgList:[]
            }
        },
        watch:{
            orgList:{
                handler(){
                  let _this = this;
                    this.$nextTick(function () {
                        setTimeout(()=>{
                            if(!_this.$refs.mySwiper)return;
                            let bullets = _this.$refs.mySwiper.$el.querySelectorAll(".swiper-pagination-bullet");
                            bullets.forEach(function (item,i) {
                                item.onmouseenter=function () {
                                    item.click();
                                }
                            });
                        },50)
                    })
                },
                immediate:true

            }
        },
        created() {
            this.getData();
        },
        methods:{
            async getData(){
                let _this = this;
                let data = await this.$http.get("/cms/cooperativeOrg/list/page",{size:40,page:1});
                let list = data.list;
                this.orgList = [];
                for(let i=0;i<Math.ceil(list.length/10);i++){
                    let arr = [];
                    for(let j=0;j<10;j++){
                        let k = i*10+j;
                        if(list[k]){
                            arr.push(list[k])
                        }
                    }
                    _this.orgList.push(arr);
                }
            },

        }
    }
</script>

<style lang="scss">
    .partner-list-wrapper{
        min-height: 470px;
        .partner-list{
            display: flex;
            >div.partner-list-item:nth-child(5n){
                margin-right: 0;
            }
        }

    }


</style>