<template>
    <div class="easy-title-container">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "EasyTitle"
    }
</script>

<style lang="scss" scoped>
.easy-title-container{
    position: relative;
    padding-left: 20px;
    font-size: 36px;
    font-weight: bold;
    color: $title;
    line-height:36px;
    &:after{
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        background-color:$primary;
        position: absolute;
        top:0;
        left:0;
     }
}
</style>