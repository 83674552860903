import axios from "./axios";

//content-disposition 中获取下载文件的文件名
function getFileName(headers) {
    if (!headers || !headers["content-disposition"]) return;

    let filename = "";
    let contentDisposition = headers["content-disposition"];

    if(contentDisposition) {
        let matches = contentDisposition.match(/filename=(.*);/);

        if (matches.length >= 2) {
            filename = decodeURI(matches[1]);
        }
    }

    return filename;
}


export default {
    /**
     * Get方法获取数据
     * get(String url,Object params) 示例get('users',{pageNum:1})
     */
    get: function(url, params) {
        return axios({
            url: url,
            method: "get",
            params: params,
        });
    },
    put: function(url, data, params) {
        return axios({
            url: url,
            method: "put",
            data: data,
            params: params,
        });
    },
    post: function(url, data, params) {
        return axios({
            url: url,
            method: "post",
            data: data,
            params: params,
        });
    },
    // 删除,示例delete('users',10)
    delete: (url, id) => {
        return axios({
            url: id ? (url + "/" + id) : url,
            method: "delete",
        });
    },
    //保存，当id大于0时为修改采用put，其他为新增采用post
    save: (url, data) => {
        return axios({
            url: url,
            method: "post",
            data: data,
        });
    },
    //权限模块使用
    getPermission: function(url, params) {
        return axios({
            url: url,
            baseURL: window.config.serverUrlPermission,
            method: "get",
            params: params,
        });
    },
    //异步下载文件 如果用服务器端给的名字，该文件名就不填
    getFileAsync: function(url,data,filename, method) {
        const req = {
            url: url,
            method: method || "post",
            data: data,
            responseType: "blob",
        };
        if (method === "get") {
            req.params = data;
            delete req.data;
        }
        return axios(req).then(res => {
            return Promise.resolve({
                filename:  filename || getFileName(res.headers) ,
                blob: res.data instanceof Blob ? res.data : new Blob([ res.data]),
            });
        });
    },
    //同步下载文件  如果用服务器端给的名字，该文件名就不填
    getFileSync: function(url,data,filename, method) {
        this.getFileAsync(url,data,filename, method).then(res => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(res.blob, res.filename);
            } else {
                let a = document.createElement("a");
                a.href = URL.createObjectURL(res.blob);
                a.download = res.filename;
                a.click();
                URL.revokeObjectURL(a.href);
            }
        });
    },
};



