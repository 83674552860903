<template>
    <div :style="{top:`${currentTop}px`}" class="right-side-nav-wrapper">
        <a class="lv-mb-24" @click="$scrollToElement('#whatCanYouDo')" href="javascript:;">
            What can you do on Lawback?
        </a>
        <a class="lv-mb-24" @click="$scrollToElement('#whatServicesCanYouEnjoy')" href="javascript:;">
            What services can you enjoy?
        </a>
        <router-link class="lv-mb-24" to="/partner?id=ourPartners">
            Our Partners
        </router-link>
        <router-link class="lv-mb-24" to="/partner?id=signingCeremony">
            Signing Ceremony
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "RightSideNav",
        data(){
            return{
                //currentTop:0,
                initTop:0,
                scrollTop:0
            }
        },
        computed:{
            currentTop(){
                let scrollTop = this.scrollTop;
                return this.initTop + (scrollTop?scrollTop:0);
            }
        },
        mounted() {
            this.initTop = window.innerHeight/2;
            document.addEventListener('scroll',this.scroll);
        },
        methods:{
            scroll(event){
                this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            }
        },
        destroyed() {
            document.removeEventListener('scroll',this.scroll);
        }
    }
</script>

<style lang="scss" scoped>
    .right-side-nav-wrapper{
        position: absolute;
        right: 0;
        width: 245px;
        min-height: 160px;
        background: #FFFFFF;
        box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.21);
        transition: top 0s ease-in-out;
        transform: translateY(-50%);
        padding: 14px 20px;
        box-sizing: border-box;
        z-index: 10;
        a,p{
            font-size: 14px;
            font-weight: bold;
            color: #666666;
            line-height: 14px;
            display: block;
            white-space: 1px;
            text-decoration: none;
            &:hover{
                color: $primary;
            }
        }
    }
</style>