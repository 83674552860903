import Vue from "vue";

Vue.prototype.$scrollToElement=(select)=>{
    document.documentElement.style.scrollBehavior="smooth";
    let t = document.querySelector(select);
    if(!t)return;
    let top = t.offsetTop;
    let height = t.offsetHeight*4;
    window.scrollTo(0,top - height);
    setTimeout(function () {
        document.documentElement.style.scrollBehavior="unset";
    },500)
}