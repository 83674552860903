<template>
<div class="lv-basic-card">
    <div class="multi-channels-wrapper">
        <div class="multi-channels-first-column">
            <div @click="toggle('on')" class="multi-channels-item multi-channels-item-option">
                <span  :class="type=='on'?'active':''">Online Promotion</span>
            </div>
            <div @click="toggle('off')" class="multi-channels-item multi-channels-item-option">
                <span @click="toggle" :class="type=='off'?'active':''">Offline Promotion</span>
            </div>
        </div>
        <div v-if="type=='on'" class="multi-channels-column">
            <div class="multi-channels-item">
                <img src="@/assets/images/home/icon_wechat@2x.png">
                <div>Wechat Official  Account</div>
            </div>
            <div class="multi-channels-item">
                <img src="@/assets/images/home/icon_ccpit@2x.png">
                <div>CCPIT and its</div>
                <div>Branches Notification</div>
            </div>
        </div>
        <div v-if="type=='on'" class="multi-channels-column">
            <div class="multi-channels-item">
                <img src="@/assets/images/home/icon_linkedin@2x.png">
                <div>Linkedin</div>
            </div>
            <div class="multi-channels-item">
                <img src="@/assets/images/home/icon_ass@2x.png">
                <div>Commerce Association</div>
                <div>Notification</div>
            </div>
        </div>
        <div v-if="type=='on'" class="multi-channels-column">
            <div class="multi-channels-item">
                <img src="@/assets/images/home/icon_facebook@2x.png">
                <div>Facebook</div>
            </div>
            <div class="multi-channels-item">
                <img src="@/assets/images/home/icon_bureau@2x.png">
                <div>Commerce Bureau</div>
                <div>Notification</div>
            </div>
        </div>
        <div v-if="type=='on'" class="multi-channels-column">
            <div class="multi-channels-item">
                <img src="@/assets/images/home/icon_website@2x.png">
                <div>Website</div>
            </div>
            <div class="multi-channels-item">
                <img src="@/assets/images/home/icon_club@2x.png">
                <div>Enterprises Clubs</div>
                <div>Notification</div>
            </div>
        </div>
        <div v-if="type=='off'" class="multi-channels-column">
            <div class="multi-channels-item">
                <img src="@/assets/images/home/icon_offline@2x.png">
                <div>Offline Lectures</div>
            </div>

        </div>
        <div v-if="type=='off'" class="multi-channels-column">
            <div class="multi-channels-item">
                <img src="@/assets/images/home/icon_iclcf@2x.png">
                <div>ICLCF</div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
    export default {
        name: "MultiChannels",
        data(){
            return{
                type:'on'
            }
        },
        methods:{
            toggle(nv){
                this.type =nv;
                console.log(this.type)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .multi-channels-wrapper{
        padding:30px;
        background-color: #F3F3F3;
        border-radius: 10px;
        display: flex;
        >.multi-channels-first-column{
            border-right:1px solid #E4E4E4;
        }
        >div.multi-channels-column{
            >div.multi-channels-item:hover{
                position: relative;
                z-index: 10;
                box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.2);
            }
        }
        >div.multi-channels-column,>.multi-channels-first-column{
            width: 210px;
            margin-right: 18px;
            >div.multi-channels-item.multi-channels-item-option{
                background: transparent;
                color: $subTitle;
                position: relative;
                &>span{
                    width: 100%;
                    display: inline-block;
                    @include local-center;
                    position: relative;
                    cursor: pointer;
                }
                &>span.active{
                    color: $primary;
                    font-weight: 600;
                    &:after{
                        content: "";
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -2px;
                        content: "";
                        width: 70%;
                        height: 3px;
                        background: #326069;
                    }
                }
            }
            >div.multi-channels-item{
                width: 210px;
                height: 180px;
                margin-bottom: 18px;
                background: #FFFFFF;
                font-size: 16px;
                font-family: Arial;
                transition: .3s;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
                overflow: hidden;
                text-align: center;
                position: relative;
                img{
                    width: 73px;
                    height: 73px;
                    object-fit: contain;
                    display: block;
                    margin: 23px auto 21px;
                }
            }
            >div.multi-channels-item:last-child{
                margin-bottom: 0;
            }
        }
        >div.multi-channels-column:first-child{
            margin-right: 29px;
        }
        >div.multi-channels-column:last-child{
            margin-right: 0px;
        }

    }
</style>