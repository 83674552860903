<template>
  <div class="home-page">
      <div  class="banner lv-mb-72">
          <!--<img src="@/assets/images/home/banner.png" alt=""-->
          <div class="banner-image-container"></div>
      </div>
    <WhyLawback></WhyLawback>
      <lawyerList></lawyerList>
     <div class="lv-basic-card">
       <easy-title class="lv-mb-24">
         Cooperative organization
       </easy-title>
        <partner-list></partner-list>
     </div>
      <RightSideNav></RightSideNav>
  </div>
</template>

<script>
  import EasyNewsItem from "../../components/EasyNewsItem/EasyNewsItem";
  import EasyTitle from "../../components/EasyTitle/EasyTitle";
  import partnerList from "../../components/partnerList/partnerList";
  import WhyLawback from "../../components/WhyLawback/WhyLawback";
  import lawyerList from "../../components/lawyerList/lawyerList";
  import RightSideNav from "../../components/RightSideNav/RightSideNav";
  export default {
    name: 'Home',
    components: {
      EasyTitle,
      EasyNewsItem,
        lawyerList,
      partnerList,
      WhyLawback,
        RightSideNav
    },
      data(){
        return {
            continents:[]
        }
      },

  }
</script>
<style lang="scss">
  .home-page {
      .banner {
          width: 100%;
          height: 600px;

          position: relative;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          .banner-image-container{
              @media screen and (min-width: 1200px){
                  height: 100%;
                  background-image:url('/images/banner.png');
                  background-attachment: fixed;
                  background-size: 120%;
                  background-repeat: no-repeat;

              }
              @media screen and (max-width: 1200px){
                  height: 100%;
                  background-image:url('/images/banner.png');
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
              }
          }
          img{
              width: 100%;
          }
      }
      .continents{

      }
  }


</style>